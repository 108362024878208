$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '../HpSharedModuleItemTile/mixins.scss';

.itemWrapper {
    margin-bottom: $sassy-spacing-large;
}

.tileImageOverlay {
    @include tile-image-overlay();
}

.tileImage {
    width: 100%;
}
