$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';

.container {
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
}

.info {
    @include sassy-font-body($type: none);
    flex-basis: 100%;
}

.info details {
    @include sassy-font-body($size: small);
}

// hide the twistie
.info details > summary {
    list-style: none;
}

// required to hide the twistie in chrome
.info details > summary::-webkit-details-marker {
    display: none;
}

.header {
    @include sassy-font-body($size: large, $type: none);
    margin: 24px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid $sassy-color-dolphin;
    line-height: 1.5;
}

.imageWrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-basis: 100%;
}

.image {
    width: 100%;
    max-width: 320px;
}

// Desktop
@include sassy-breakpoint-above(tablet-landscape) {
    .info {
        flex-basis: 60%;
    }
    .header {
        @include sassy-font-header($size: xx-large);
        line-height: 1.25;
    }
    .imageWrap {
        flex-basis: 40%;
        align-items: flex-end;
    }
    .image {
        max-width: 400px;
    }
}
