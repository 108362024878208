$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_fonts';

.wrapper {
    @include sassy-breakpoint-below(mobile) {
        @include primary-viewport-width;
    }

    @include sassy-breakpoint-above(desktop) {
        max-width: $dbl-max-width * 0.75; // has .col9 which is 75% of width // from dbl/exports/scss/_layout
        margin: 0 auto;
    }
}

.subHeader {
    @include sassy-font-header($size: medium);
    text-align: center;
    margin: 0;
    padding: $sassy-spacing-large 0 $sassy-spacing-medium;

    @include sassy-breakpoint-above(tablet-landscape) {
        padding-top: $sassy-spacing-x-large;
    }
}

.swiperList {
    align-items: flex-start;
}
