$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import './RecentlyViewedItemsCarousel.scss';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;

    &.xSmall {
        height: calc(#{$image-size-x-small-carousel} + #{$image-height-spacing-carousel});

        @include sassy-breakpoint-below(tablet-portrait) {
            height: calc(#{$image-size-x-small-mobile-carousel} + #{$image-height-spacing-swiper});
        }
    }

    &.small {
        height: calc(#{$image-size-small-carousel} + #{$image-height-spacing-carousel});

        @include sassy-breakpoint-below(tablet-portrait) {
            height: calc(#{$image-size-small-mobile-carousel} + #{$image-size-small-spacing});
        }
    }

    &.large {
        height: $image-size-large-carousel;

        @include sassy-breakpoint-below(tablet-portrait) {
            height: calc(#{$image-size-large-mobile-carousel} + #{$image-height-spacing-swiper});
        }
    }
}
