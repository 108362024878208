$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

.header {
    margin-bottom: $sassy-spacing-x-small;

    .isSwiperCarousel & {
        margin-bottom: $sassy-spacing-small;
        margin-left: $sassy-spacing-small;
    }

    .alignTitleLeft & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin: 0;
    }

    .centerTitle & {
        text-align: center;
        margin-bottom: $sassy-spacing-larger;
    }
}

.title {
    @include sassy-font-header($size: large);
    margin: 0 0 $sassy-spacing-x-small;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-header($size: medium);
        margin: 0;
    }
}

.viewMore {
    @include sassy-font-body;

    .alignTitleLeft & {
        @include sassy-font-body($size: small);
        display: block;
        margin-top: $sassy-spacing-x-small;
    }
}

.carousel {
    width: 100%;
    min-width: 0;

    .alignTitleLeft & {
        // max-width and margin values comes from PdpRespCondensedRecentlyViewed component,
        // which is only other case of a carousel like this sharing the row with a header
        max-width: 1087px;
        margin-left: auto;
    }
}
