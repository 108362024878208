$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_hidden';

.link {
    // required to render focus outline on focusable element containing block-like
    // elements
    display: block;
}

.wrapper {
    width: 100%;
    margin-top: $sassy-spacing-large;
}

.image {
    display: block;
    max-height: 100%;
    width: 100%;
    height: auto;

    @include sassy-breakpoint-above(tablet-landscape) {
        &.isFullBleed {
            @include primary-viewport-width;
            max-height: 180px;
            object-fit: cover;
        }
    }
}

.imageCopy {
    @include sassy-visually-hidden;
}

.sectionWrapper {
    @include sassy-breakpoint-below(tablet-portrait) {
        @include primary-viewport-width;
    }
}

.incentivesWrapper {
    grid-template-columns: 1fr 1fr 1fr;

    @include sassy-breakpoint-below(tablet-portrait) {
        grid-template-columns: 1fr;
    }
}
