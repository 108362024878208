$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

.centerWithoutBackground {
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
