$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_spacing';

:local {
    .wrapper {
        overflow: hidden;
    }
    .relative {
        position: relative;
    }

    .list {
        display: flex;
        overflow: visible;
        align-items: center;
        margin: 0;
        padding: 0;
        height: 100%;
        &.itemsTopAlign {
            align-items: flex-start;
        }
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .arrowWrapper {
        width: 44px;
        height: 44px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($sassy-color-white, 0.9);
        border-radius: 100%;
        @include sassy-z-index(bump);
    }
    .prevArrowWrapper {
        left: $sassy-spacing-x-small;
    }
    .nextArrowWrapper {
        right: $sassy-spacing-x-small;
    }
    .arrow {
        fill: $sassy-color-iron;
        width: 24px;
        height: 24px;
    }
    .prevArrow {
        margin-right: 4px;
    }
    .nextArrow {
        margin-left: 4px;
    }
}
