$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_spacing';

.text {
    @include sassy-font-body;
}

.image {
    display: block;
    max-height: 100%;
    max-width: 100%;
}

.imageOverlay {
    position: relative;
}

.link {
    @include sassy-buyer-link-no-decoration();
    // required to render focus outline on focusable element containing block-like
    // elements
    display: block;
}

.header {
    @include sassy-font-header;
    // do not inherit anchor color on hover
    color: initial !important;
    margin-top: $sassy-spacing-small;

    @include sassy-breakpoint-below(mobile) {
        @include sassy-font-header($size: x-small);
    }
}

.description {
    color: initial !important;
    margin-top: $sassy-spacing-x-small;
}

.cta {
    margin-top: $sassy-spacing-x-small;
    text-decoration: underline;
}
