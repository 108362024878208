$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';

@mixin primary-page-width($offset: $sassy-spacing-larger) {
    width: calc(100% - (2 * #{$offset}));
}

:local {
    .setOffset {
        @include primary-page-width();
        max-width: 1440px;
        margin: 0 auto;

        @include sassy-breakpoint-below(tablet-landscape) {
            @include primary-page-width($sassy-spacing-large);
        }
        @include sassy-breakpoint-below(mobile) {
            @include primary-page-width($sassy-spacing-small);
        }
    }

    .viewportContainer {
        position: relative;
        width: 100vw;
        left: calc((100vw - 100%) / -2);
    }
}
