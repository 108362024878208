$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '../variables';

.tile {
    @include sassy-font-body(small);
    max-width: 100%; // For ellipsis to work correctly
}

.info {
    @include sassy-font-body(small);
    padding-top: $sassy-spacing-x-small;
    line-height: $info-line-height;
    color: $sassy-color-noir;
}
