$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_webkitLineClamp';

$title-height: 2.6em; // line-height * font-size * 2 lines

.title {
    @include sassy-font-body($size: none);
    @include webkitLineClamp($lineClamp: 2);
    font-size: 1em; // size will be inherited by wrapper
    margin: 0;
    text-align: left;
    line-height: 1.3;
    display: block;
}

.link {
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
    max-height: $title-height;
    width: 100%; // For ellipsis in art to work correctly
    color: currentColor;

    &.imageXLarge {
        font-size: 20px;
    }
}

.artOneLine {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    &.noCreator {
        margin-top: 18px;
    }
}

.ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
