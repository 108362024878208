@import '~dibs-sassy/exports/_spacing';

$gap-sizes: (
    //1px accounts for browsers inconsistencies handling scroll snap
    'none': 1px,
    'xSmall': $sassy-spacing-x-small,
    'small': $sassy-spacing-small,
    'medium': $sassy-spacing-medium,
    'large': $sassy-spacing-large
);

@mixin item-gap {
    @each $name, $sassy-var in $gap-sizes {
        $sassy-var: calc(#{$sassy-var} / 2);

        &.#{$name}Horizontal {
            padding: 0 $sassy-var;
        }
        &.#{$name}Vertical {
            padding: $sassy-var 0;
        }
    }
}

@mixin wrapper-gap {
    @each $name, $sassy-var in $gap-sizes {
        $sassy-var: calc(#{-$sassy-var} / 2);

        &.#{$name}Horizontal {
            margin: 0 $sassy-var;
        }
        &.#{$name}Vertical {
            margin: $sassy-var 0;
        }
    }
}

$scroll-padding-sizes: (
    'none': 0,
    'small': $sassy-spacing-small,
    'large': $sassy-spacing-large,
);

@mixin scroll-padding {
    @each $name, $sassy-var in $scroll-padding-sizes {
        &.#{$name}HorizontalScrollPadding {
            scroll-padding: $sassy-var;
            padding: 0 $sassy-var;
        }
        &.#{$name}VerticalScrollPadding {
            scroll-padding: $sassy-var;
            padding: $sassy-var 0;
        }
    }
}
