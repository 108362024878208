$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_colors';

.container {
    margin: $sassy-spacing-larger auto 0 auto;
    padding: $sassy-spacing-large 0 $sassy-spacing-medium 0;
    background: $sassy-color-buyer-tertiary;
    text-align: center;
}

.titleWrapper {
    margin-bottom: $sassy-spacing-large;
    @include sassy-font-body;
}

.title {
    @include sassy-font-header($size: x-large);
    margin-top: 0;
    margin-bottom: $sassy-spacing-x-small;
}

.carouselWrapper {
    overflow: hidden;
}

.item {
    width: 100%;
    height: 100%;
    background-color: $sassy-color-white;
    padding: $sassy-spacing-x-small;
    overflow: hidden; // make sure all squares are squares (crop irregular images)
}

.innerItem {
    overflow: hidden;
    position: relative; // required for HeartWrapper
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heartWrapper {
    width: 23px; // 23px hardcode reason https://1stdibs.atlassian.net/browse/SEARCH-1347?focusedCommentId=465972
}

.itemImage {
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.arrow {
    width: $sassy-spacing-x-large;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.carouselWrapper:not(.showDotsAndArrows) .wrapper {
    padding: 0 $sassy-spacing-x-large; // compensate missing arrow
}

.arrowLeft {
    margin-right: 0;
}

.arrowRight {
    margin-left: 0;
}

.dotsWrapper {
    margin-top: $sassy-spacing-medium;
    text-align: center;
}

.isMobile {
    &.container {
        @include primary-viewport-width;
    }

    .carouselWrapper {
        overflow: scroll;
    }

    .carouselWrapper:not(.showDotsAndArrows) .wrapper {
        padding: 0 $sassy-spacing-medium; // compensate missing arrow
    }
}
