@mixin webkitLineClamp($lineClamp) {
    @supports (-webkit-line-clamp: $lineClamp) {
        display: -webkit-box;
        overflow: hidden;
        & {
            /* autoprefixer: off */
            // https://github.com/postcss/autoprefixer/issues/776
            -webkit-box-orient: vertical;
        }
        -webkit-line-clamp: $lineClamp;
    }
}
