$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.title {
    @include sassy-font-header($size: medium, $type: italic);
    padding: $sassy-spacing-small 0 $sassy-spacing-x-small;
    text-align: center;
    width: 100%;
}

.shopNow {
    margin: auto;
    padding-bottom: $sassy-spacing-small;
}

.products {
    position: relative;
    padding: 0 $sassy-spacing-small $sassy-spacing-medium;
    min-height: 222px;
}

.productTile {
    overflow: hidden;
}

.category {
    background: $sassy-color-white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: $sassy-spacing-medium auto;
    padding: 0 $sassy-spacing-small;
    width: 100%;
}
