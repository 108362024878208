$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

@keyframes heart-beat {
    0%,
    100% {
        transform: scale(1);
    }
    33% {
        transform: scale(0.8);
    }
    66% {
        transform: scale(1.2);
    }
}

.likeIcon {
    fill: transparent;
    stroke-width: 12px;
    stroke: $sassy-color-buyer-primary;
    transition: fill 0.2s ease;

    &.showHeartBeat {
        animation: heart-beat 0.7s ease-in-out;
    }

    &.isFilled {
        fill: $sassy-color-buyer-primary;
    }

    &.dark {
        stroke: $sassy-color-noir;

        &.isFilled {
            fill: $sassy-color-noir;
        }
    }

    &.light {
        stroke: $sassy-color-white;

        &.isFilled {
            fill: $sassy-color-white;
        }
    }
}
