$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

.pinchZoomContainer {
    touch-action: pan-x pan-y;
}

.zoomImageWrapper {
    &:global(.transition) {
        transition: transform 250ms linear;
        pointer-events: none;
    }
}

.zoomImage {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.spinner {
    top: 45%;
}

.zoomOut {
    width: 40px;
    height: 40px;
    background-color: $sassy-color-white;
}

.zoomOutIcon {
    width: 20px;
    height: 20px;
}
