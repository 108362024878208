$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';

/**
 * TODO: Fix webpack. Without this var webpack doesn't add userType specificity classes in storybook for carousel component.
 * Because of this all imports that have a userType specific styles override carousel component styles.
 */
$user-type: 'buyer';

.wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: $sassy-spacing-medium;

    &.horizontal {
        flex-direction: row;
    }

    &.vertical {
        flex-direction: column;
        height: 100%;
    }
}
