$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.addTopGap {
    margin-top: $sassy-spacing-x-small;

    @include sassy-breakpoint-above(tablet-landscape) {
        margin-top: $sassy-spacing-larger;
    }
}

.titleContainer {
    text-align: center;
    margin: $sassy-spacing-large;
}

.titleWrapper {
    @include sassy-breakpoint-below(mobile) {
        margin-top: 0;
    }
}

.title {
    @include sassy-font-header($size: x-large);

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-header;
    }
}

.subtitle {
    @include sassy-font-body($size: large);
    margin-top: $sassy-spacing-x-small;
}

.viewMore {
    display: flex;
    justify-content: center;
    font-size: 16px;
    @include sassy-font-body;
}
