$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_fonts';

.priceWrapper {
    @include sassy-font-body($size: x-small);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    bottom: $sassy-spacing-xx-small;

    &.pillWrapper {
        padding: 4px $sassy-spacing-x-small 0;
        border-radius: 16px;
        white-space: nowrap;
        background-color: $sassy-color-moonstone;
    }
}

.paddle {
    height: 12px;
    width: 10px;
    margin-right: $sassy-spacing-xx-small;
}
