$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_spacing';

:local {
    .iconWrapper {
        background: none;
        border: none;
        height: 100%;
    }

    .heart {
        @include sassy-z-index(bump);
        background: none;
        border: none;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
        }
    }

    .heartCount {
        @include sassy-font-body($size: x-small);
        line-height: 1;
        margin-top: 5px;
    }

    .icon {
        @include sassy-z-index(bump);
        position: relative;
        display: block;
        height: 26px;
        width: 26px;
        transition: fill 0.2s ease;

        &.hoverClass {
            &:hover {
                fill: $sassy-color-buyer-primary;

                &.dark {
                    fill: $sassy-color-noir;
                }

                &.light {
                    fill: $sassy-color-white;
                }
            }
        }

        &.isStrokeWidthHeavy {
            stroke-width: 18px;
        }
    }

    svg.icon:not(:root) {
        overflow: visible;
        padding: 1px;
    }

    .pulseIcon {
        position: absolute;
        fill: $sassy-color-noir;
        opacity: 0.5;
        stroke: $sassy-color-noir;
        stroke-width: 12px;
        overflow: visible !important; // Overrides overflow so the heart animation is not cut-off
        height: 30px;
        width: 30px;
        top: 50%;
        left: 50%;

        animation: swell 1.5s ease 0.1s 3 normal both;

        &.isFilled {
            animation-iteration-count: 1;

            &.stopAnimation {
                animation-play-state: paused;
                stroke-width: 0;
                opacity: 0;
            }
        }
    }

    @keyframes swell {
        0% {
            transform: translate(-50%, -50%);
        }
        100% {
            transform: translate(-50%, -50%) scale(1.5);
            opacity: 0;
        }
    }
}
