$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_webkitLineClamp';

.link {
    @include sassy-buyer-link-no-decoration();
    // requires block to render outline when containing block children
    display: block;
}

.item {
    @include sassy-font-body($size: small, $type: none);
    margin: $sassy-spacing-small 0;
    color: $sassy-color-satan;
}

.image {
    width: 100%;
    height: 100%;
}

.title {
    margin-top: $sassy-spacing-small;
    @include webkitLineClamp($lineClamp: 3);
}
