$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

:local {
    @mixin dotSize($size: 9px) {
        min-width: $size;
        min-height: $size;
    }

    .dotsWrapper {
        margin: auto;
        overflow: hidden;
        height: 16px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        transform: translate3d(0, 0, 0);
    }

    .dot {
        @include dotSize;
        display: flex;
        visibility: hidden;
        align-items: center;
        margin: 0 $sassy-spacing-x-small * 0.5;
        cursor: pointer;

        &.isVisible {
            visibility: visible;
        }

        &:after {
            @include dotSize;
            content: '';
            display: block;
            background: $sassy-color-dolphin;
            border-radius: 50%;
        }

        &.small {
            &:after {
                @include dotSize(7px);
            }
        }
        &.tiny {
            &:after {
                @include dotSize(5px);
            }
        }

        &:hover,
        &.isCurrent {
            &:after {
                background: $sassy-color-buyer-secondary;
            }
        }

        &.isCurrentDark {
            &:after {
                background: $sassy-color-noir;
            }
        }
    }
}
