$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

@mixin headerFont($size) {
    @include sassy-font-header($size);
    em {
        // header supports TinyMCE text, which uses em for italic
        @include sassy-font-header($size, $type: italic);
    }
}

.eyeBrow {
    @include sassy-font-sub-header($size: medium, $type: normal);
    margin-bottom: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-portrait) {
        @include sassy-font-sub-header($size: small, $type: normal);
    }
}

.text {
    &.textAlignCenter {
        text-align: center;
    }
    // content white should apply only to desktop & tablet
    &.contentColorWhite {
        color: $sassy-color-white;
    }

    padding: $sassy-spacing-small 0;
}

.header {
    margin-bottom: $sassy-spacing-small;

    @include sassy-breakpoint-above(desktop) {
        @include headerFont($size: xxxx-large);
    }

    @include sassy-breakpoint-between(tablet-portrait, tablet-landscape) {
        @include headerFont($size: vin-diesel);
    }

    @include sassy-breakpoint-below(mobile) {
        @include headerFont($size: xx-large);
    }

    &,
    em {
        // override header line-height
        line-height: 1 !important;
    }
}

.dek {
    @include sassy-font-header($size: x-large);
    margin-bottom: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-landscape) {
        @include sassy-font-header($size: small);
    }
}

.cta {
    background-color: $sassy-color-noir;
    color: $sassy-color-white;

    // !important added to overwrite default <Button /> styles
    &:hover {
        background-color: $sassy-color-noir !important;
        color: $sassy-color-white !important;
    }

    &.outline {
        color: $sassy-color-noir;
        border-color: $sassy-color-noir;

        background-color: transparent;
        &:hover {
            background-color: transparent !important;
        }
        &.contentColorWhite {
            color: $sassy-color-white;
            border-color: $sassy-color-white;
        }
    }
}
