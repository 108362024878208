@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

@mixin tile-text() {
    @include sassy-breakpoint-below(mobile) {
        margin: 0 $sassy-spacing-x-small;
    }
}

@mixin tile-image-overlay() {
    @include sassy-breakpoint-below(mobile) {
        @include primary-viewport-width;
        max-width: none;
    }
}
