$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_hidden';

.wrapper {
    margin-bottom: $sassy-spacing-large;
}

.player {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.iframeWrapper {
    padding: 56.25% 0 0 0;
    position: relative;
}

.leftBlock {
    @include sassy-font-body($type: none);
    display: flex;
    align-items: center;
    padding: $sassy-spacing-medium;
    background: $sassy-color-moonstone;
    height: 100%;

    @include sassy-breakpoint-below(tablet-portrait) {
        flex-direction: column;
        text-align: center;
    }
}

.rightBlock {
    @include sassy-breakpoint-below(tablet-portrait) {
        margin-bottom: $sassy-spacing-small;
    }
}

.profileImageWrapper {
    min-width: 160px;
    min-height: 160px;
    margin-right: $sassy-spacing-medium;

    @include sassy-breakpoint-below(tablet-landscape) {
        min-width: 120px;
        min-height: 120px;
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        margin-right: 0;
        margin-bottom: $sassy-spacing-large;
    }
}

.profileImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.collectionImageWrapper {
    position: relative;
    width: 100%;
    height: 395px;
    overflow: hidden;

    @include sassy-breakpoint-below(mobile) {
        height: 250px;
    }
}

.collectionImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.linkText {
    @include sassy-font-header(medium);
    margin: $sassy-spacing-small 0;
    text-align: center;
}

.hidden {
    @include sassy-visually-hidden;
}

.productsRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
