$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

.wrapper {
    margin-top: $sassy-spacing-large;
}

.isSwiperCarousel {
    @include primary-viewport-width;
}

.title {
    @include sassy-font-header($size: x-large);
    margin: 0;

    @include sassy-breakpoint-below(mobile) {
        @include sassy-font-header($size: medium);
    }
}

.header {
    text-align: center;
    margin-bottom: $sassy-spacing-larger;

    .isSwiperCarousel & {
        margin-bottom: $sassy-spacing-small;
    }
}
