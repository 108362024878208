$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_zindex';

.item {
    max-height: 360px;
    text-align: center;
    padding: $sassy-spacing-x-small;
}

.link {
    @include sassy-buyer-link-no-decoration();
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $sassy-color-moonstone;
    padding: 21px 0;
    line-height: 1;
    height: 100%;
}

.title {
    @include sassy-font-header(medium);
    color: $sassy-color-noir;
    padding-bottom: $sassy-spacing-x-small;

    @include sassy-breakpoint-above(desktop) {
        padding-bottom: $sassy-spacing-small;
    }
}

.imageContainer {
    @include sassy-z-index(none);
    height: 100px;
    width: 100px;

    @include sassy-breakpoint-above(tablet-portrait) {
        height: 120px;
        width: 120px;
    }

    @include sassy-breakpoint-above(desktop) {
        height: 150px;
        width: 150px;
    }
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.cta {
    @include sassy-font-sub-header($type: normal);
    padding-top: 12px;

    @include sassy-breakpoint-above(desktop) {
        padding-top: $sassy-spacing-small;
    }
}
