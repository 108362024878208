$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_zindex';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

.contentContainer {
    margin: auto;
    opacity: 0;
    position: relative; // contain .overlayLink
    max-width: $dbl-max-width;
    align-items: center; // center image when it is smaller than .imageContainer
    height: 100%;

    // grid vars
    --grid-gap: #{$sassy-spacing-large};
    @include sassy-breakpoint-below(tablet-landscape) {
        --grid-gap: #{$sassy-spacing-small};
    }
    // mimic gutter from design grid as first/last column + grid-gap
    // first/last column = gutter width - grid-gap
    // responsive gutter value is set to --sassy-layout-offset on page root
    --grid-gutter: calc(var(--sassy-layout-offset) - var(--grid-gap));

    // grid layout
    display: grid;
    grid-gap: 0 var(--grid-gap); // recently deprecated, use as fallback
    gap: 0 var(--grid-gap);
    grid-template-rows: [text image] 1fr;
    grid-template-columns:
        [gutter-left] var(--grid-gutter)
        [text-start] 1fr
        [image-start text-end] 1fr
        [] var(--grid-gutter)
        [gutter-right image-end];

    @include sassy-breakpoint-below(mobile) {
        grid-template-rows: [image] 1fr [text] auto;
        grid-template-columns:
            [gutter-left image-start] var(--grid-gutter)
            [text-start] 1fr
            [text-end] var(--grid-gutter)
            [gutter-right image-end];
        &.fullWidth {
            grid-template-rows: [image-start] 1fr [text] 1fr [image-end];
        }
    }

    &.reverse {
        direction: rtl;
        > * {
            // direction affects text as well as grids--prevent children from inheriting
            direction: initial;
        }
    }
    &.visible {
        opacity: 1;
    }
    &.border {
        @include sassy-breakpoint-above(tablet-portrait) {
            border-top: thin solid $sassy-color-dolphin;
            border-bottom: thin solid $sassy-color-dolphin;
            padding: $sassy-spacing-medium 0;
        }
        @include sassy-breakpoint-above(desktop) {
            padding: $sassy-spacing-large 0;
        }
    }
}

.textContainer {
    // bump above .imageContainer
    @include sassy-z-index(bump);
    grid-area: text;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.image {
    width: 100%;
    &.fullWidth {
        height: 100%;
        object-fit: cover;
    }
}

.imageContainer {
    grid-area: image;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    opacity: 1;
    &.fullWidth {
        height: 100%;
        grid-column: gutter-left / gutter-right;
    }
}

.overlayLink {
    // equal zindex with .textContainer
    @include sassy-z-index(bump);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    &.right {
        right: 50%;
    }
    &.left {
        left: 50%;
    }
    &.top {
        top: 50%;
    }
}
