$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

:local {
    .moreOptionsMultisku {
        circle:nth-child(1) {
            fill: $sassy-color-dealer-primary;
        }
        circle:nth-child(2) {
            fill: $sassy-color-alert-primary;
        }
        circle:nth-child(3) {
            fill: $sassy-color-buyer-primary;
        }
    }
}
