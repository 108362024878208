$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

:local {
    .badgeIcon {
        width: 12px;
        height: 10px;
        margin: 0 6px 2px;
    }

    .badgeText {
        color: $sassy-color-iron;
        margin-right: 6px;
    }
}
