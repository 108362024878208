@import '../exports/_colors';
@import '../exports/_breakpoints';
@import '../exports/_zindex';

// Include animations only for desktop resolution
@mixin __INTERNAL_ONLY_BREAKPOINT__() {
    @include sassy-breakpoint-above(tablet-landscape) {
        @content;
    }
}

@mixin __INTERNAL_ONLY_ANIMATION_TRANSITION__($duration: 0.3s, $function: ease) {
    backface-visibility: hidden;
    transition: all $duration $function;
}

@mixin __INTERNAL_ONLY_ELEMENT_POSITION__($position: 0, $important: false) {
    $bang: '';

    @if $important {
        $bang: '!important';
    }

    position: absolute unquote($bang);
    top: $position;
    left: $position;
    right: $position;
    bottom: $position;
}

@mixin sassy-animate-inset-border-hover(
    $size: 18px,
    $color: $sassy-color-white,
    $duration: 0.3s,
    $size-to: 18px
) {
    @include __INTERNAL_ONLY_BREAKPOINT__ {
        position: relative;
        &:before {
            @include __INTERNAL_ONLY_ELEMENT_POSITION__($size);
            @include __INTERNAL_ONLY_ANIMATION_TRANSITION__($duration);
            content: '';
            border: 1px solid $color;
            opacity: 0;
            @include sassy-z-index(bump);
        }

        &:hover {
            &:before {
                @if ($size != $size-to) {
                    @include __INTERNAL_ONLY_ELEMENT_POSITION__($size-to);
                }
                opacity: 1;
            }
        }
    }
}

@mixin __INTERNAL_ONLY_DEFAULT_SCALE__($scale-from: 1.03, $duration: 0.3s) {
    @include __INTERNAL_ONLY_ANIMATION_TRANSITION__($duration);
    transform: translateZ(0) scale($scale-from);
}

@mixin __INTERNAL_ONLY_SCALE_HOVER_STATE__($scale-to: 1) {
    transform: translateZ(0) scale($scale-to);
}

@mixin sassy-animate-scale-hover($scale-from: 1.03, $scale-to: 1, $duration: 0.3s, $child: null) {
    @if ($child) {
        #{$child} {
            @include __INTERNAL_ONLY_DEFAULT_SCALE__($scale-from, $duration);
        }
    } @else {
        @include __INTERNAL_ONLY_DEFAULT_SCALE__($scale-from, $duration);
    }

    @include __INTERNAL_ONLY_BREAKPOINT__ {
        &:hover {
            @if ($child) {
                #{$child} {
                    @include __INTERNAL_ONLY_SCALE_HOVER_STATE__($scale-to);
                }
            } @else {
                @include __INTERNAL_ONLY_SCALE_HOVER_STATE__($scale-to);
            }
        }
    }
}

@mixin sassy-animate-overlay-hover(
    $color: $sassy-color-buyer-primary,
    $opacity: 0.25,
    $duration: 0.3s
) {
    @include __INTERNAL_ONLY_BREAKPOINT__ {
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: opacity $duration ease;
            opacity: 0;
            background: $color;
        }

        &:hover {
            &:after {
                opacity: $opacity;
            }
        }
    }
}

@mixin sassy-animate-shadow-hover() {
    transition: box-shadow 0.15s ease;

    &:hover {
        box-shadow: 1px 2px 6px 0 rgba($sassy-color-noir, 0.3);
    }
}

@keyframes shimmerLoadingBackground {
    0% {
        background-position: 50% 0;
    }
    100% {
        background-position: (-50%) 0;
    }
}

@mixin sassy-animate-loading-shimmer {
    animation-name: shimmerLoadingBackground;
    animation-direction: alternate;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background-image: linear-gradient(
        to right,
        $sassy-color-dolphin 0%,
        $sassy-color-moonstone 50%,
        $sassy-color-moonstone 51%,
        $sassy-color-dolphin 100%
    );
    background-size: 400% 400%;
    backface-visibility: hidden;
    transform: translateZ(0);
}

@mixin sassy-pulsing-dot-animation($color: $sassy-color-buyer-primary, $size: 18px) {
    $animated-dot-growth: 0.7;

    &:before {
        content: '';
        display: block;
        height: $size;
        width: $size;
        background-color: $color;
        border-radius: 50%;
        animation: pulse-animation 1.5s ease 0.1s infinite, swell 1.5s ease infinite;
        // dot has to have the same amount of margin as animated dot growth
        // to hold space for animation so it would not overlap with elements around it
        margin: calc(#{$size} * #{$animated-dot-growth});
    }

    @keyframes swell {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
    }

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0 rgba($color, 0.5), 0 0 0 0 rgba($color, 0.5);
        }
        100% {
            box-shadow: 0 0 0 calc(#{$size} * #{$animated-dot-growth}) rgba($color, 0),
                0 0 0 0 rgba($color, 0.5);
        }
    }
}
