$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

$focusPadding: 4px;

// ENSURE FOCUS OUTLINES ARE VISIBLE FOR HERO BANNER ITEMS
// special margin and padding are required because multiple containers in the
// carousel apply overflow: hidden; with children that fill the container using
// width: 100%. these two styles in combination hide the child's focus outline
// because the outline naturally falls outside the child's border area (the
// outermost part of the element used in most width calculations). in this
// specific case, .bannerImgContainer has a focus outline, width: 100%, and an
// ancestor with overflow: hidden. its parent, .carouselItem, needs to make
// space for the outline that will not be stolen by .bannerImgContainer so give
// it padding. to preserve the overall size of the carousel, use a negative
// margin on .carouselWrapper to offset the padding.
//
// https://developer.mozilla.org/en-US/docs/Web/CSS/outline#Description
.carouselWrapper {
    // increase wrapper size; will be offset with padding on .carouselItem
    margin: -$focusPadding;
    // undo carousel width 100% bc it doesn't affect carousel layout but does
    // affect wrapper margin and padding
    width: unset;
}

.fullBleedListWrapper {
    // make sure all the modules are flush with the top of the page on mobile
    @include sassy-breakpoint-below(tablet-portrait) {
        align-items: flex-start !important;
    }
}

.listWrapper {
    align-items: stretch;
}

.carouselItem {
    // container for a.bannerImgContainer
    padding: $focusPadding;
    height: auto;
}

.moduleContainer {
    padding-top: $sassy-spacing-large;
    &.isFullBleed {
        padding-top: 0;
    }
    @include sassy-breakpoint-above(tablet-landscape) {
        &.isFullBleed {
            @include primary-viewport-width;
            overflow: hidden;
        }
    }
    &.fullWidth {
        @include primary-viewport-width-tablet-fix;
    }
}
