$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '../RecommendedItemsCarousel/RecommendedItemsCarousel.scss';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(#{$image-height-resp} + #{$sassy-spacing-x-small});

    @include sassy-breakpoint-below(mobile) {
        height: calc(#{$image-size-mobile});
    }
}
