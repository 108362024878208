$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_hidden';

.imageCopy {
    @include sassy-visually-hidden;
}

// ENSURE FOCUS OUTLINES ARE VISIBLE FOR HERO BANNER ITEMS	// ENSURE FOCUS OUTLINES ARE VISIBLE FOR HERO BANNER ITEMS
// special margin and padding are required because multiple containers in the	// special margin and padding are required because multiple containers in the
// carousel apply overflow: hidden; with children that fill the container using	// carousel apply overflow: hidden; with children that fill the container using
$focusPadding: 4px;

.bannerImgContainer {
    // required to render focus outline on element containing block-like elements
    display: block;
    opacity: 0;

    &.isVisible {
        opacity: 1;
    }
}

.bannerImgContainer,
.bannerImg {
    width: 100%;
}

.bannerImg {
    @include sassy-breakpoint-above(tablet-landscape) {
        &.isFullBleed {
            max-height: 400px;
            object-fit: cover;
        }
    }
}
