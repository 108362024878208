$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_animations';

// returns condition as bit flag
@function greater-than($a, $b) {
    @return clamp(0, #{$a} - #{$b}, 1);
}

.shimmer {
    @include sassy-animate-loading-shimmer;
}

.placeholder {
    // --prop-max-width
    //      set by hook on style prop, attempt to assign first
    // --lazy-media-max-width
    //      potentially set on element or ancestor element, first fallback
    // --lazy-media-max-size:
    //      potentially set on element or ancestor element, second fallback
    // max-size is most commonly the max-size/actual size of the media element's container
    --max-width: var(--prop-max-width, var(--lazy-media-max-width, var(--lazy-media-max-size)));
    --max-height: var(--prop-max-height, var(--lazy-media-max-height, var(--lazy-media-max-size)));
    object-fit: scale-down;

    // full page width (excluding gutters) is the min max width constraint. needed
    // for scenario when container max width is larger than the viewport.
    --responsive-width: (100vw - #{$sassy-spacing-small * 2});

    // select smallest dimension constraint to prevent up-scaling or container blowout
    --adjusted-width: min(var(--media-width) * 1px, var(--max-width), var(--responsive-width));

    // NFTS-665 conditional calcs removed.
    // google chrome changed how divide by zero is handled in calc. previously,
    // divide by zero would generate an error and break the css (a feature that
    // was used on purpose here). chrome 100 treats divide by zero the same as
    // divide by one. this violates the css spec so who knows why google did it.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/calc#notes

    // scale image using derived aspect-ratio and scale factor.
    --adjusted-height: min(var(--media-height) * 1px, var(--max-height));
    // no 3rd arg bc viewport height is not a constraint

    --scaled-by-width: calc(var(--adjusted-width) / var(--media-width));
    --scaled-by-height: calc(var(--adjusted-height) / var(--media-height));
    // choose the min of scale factors to fit the media to the smallest constraint
    --chosen-scale: min(var(--scaled-by-width), var(--scaled-by-height));

    width: calc(var(--chosen-scale) * var(--media-width));
    height: calc(var(--chosen-scale) * var(--media-height));
}
