$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_spacing';

.link {
    // ensure space for focus outline
    outline-offset: -5px;
    display: block;
    width: 100%;
    height: 100%;
    @include sassy-buyer-link-no-decoration();
    @include sassy-breakpoint-above(tablet-portrait) {
        padding: 0 $sassy-spacing-x-small;
    }
    @include sassy-breakpoint-below(mobile) {
        padding: 0 2px;
    }
}

.imageWrapper {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include sassy-breakpoint-above(tablet-portrait) {
        height: 120px;
    }

    @include sassy-breakpoint-above(tablet-landscape) {
        height: 150px;
    }
}

.image {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}

.title {
    @include sassy-breakpoint-above(tablet-portrait) {
        @include sassy-font-sub-header($type: normal);
    }
    @include sassy-breakpoint-below(mobile) {
        @include sassy-font-body($size: small);
    }
    display: block;
    text-align: center;
    margin-top: $sassy-spacing-small;
    min-height: 32px;

    @include sassy-breakpoint-above(tablet-landscape) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
