$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';

$carousel-height-desktop: 410.38px;
.dotsWrapper {
    margin-top: $sassy-spacing-medium;
    text-align: center;
}

.spinContainer {
    margin: 0 auto;

    //calculating spinner place to be at "25% from top"
    height: calc($carousel-height-desktop / 2);
    margin-bottom: calc($carousel-height-desktop / 2);

    @include sassy-breakpoint-below(tablet-landscape) {
        height: calc($carousel-height-desktop / 2 - 15px);
        margin-bottom: calc($carousel-height-desktop / 2 - 15px);
    }

    @include sassy-breakpoint-below(tablet-portrait) {
        height: calc($carousel-height-desktop / 2 - 19px);
        margin-bottom: calc($carousel-height-desktop / 2 - 19px);
    }

    @include sassy-breakpoint-below(mobile) {
        height: calc($carousel-height-desktop / 2 + 7.5px);
        margin-bottom: calc($carousel-height-desktop / 2 + 7.5px);
    }
}

.list {
    align-items: stretch;
}

.carouselItem {
    height: auto;
}
