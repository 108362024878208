$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

.dot {
    display: inline-block;
    margin: 0 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $sassy-color-dolphin;
    cursor: pointer;

    &:hover {
        background-color: $sassy-color-buyer-secondary;
        @media (hover: none) {
            background-color: $sassy-color-dolphin;
        }
    }

    &.activeDot {
        background-color: $sassy-color-buyer-secondary;
    }
}
