$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';

// Vars for carousel
$image-size-x-small-carousel: 148px;
$image-size-x-small-mobile-carousel: 120px;

$image-size-small-carousel: 180px;
$image-size-small-mobile-carousel: 180px;
$image-size-small-spacing: $sassy-spacing-x-small;

$image-size-large-carousel: 205px;
$image-size-large-mobile-carousel: 120px;

$image-height-spacing-carousel: $sassy-spacing-x-small;
$image-height-spacing-swiper: 0px;
$heart-size-carousel: 26px;

// Vars for carousel with left-aligned title
$image-size-left-aligned-title: 110px;

// Vars for swiper
$image-size-swiper: 90px;
$heart-size-swiper: 20px;

.itemWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &.xSmall {
        width: 100%;

        &.swiperItem {
            background-color: $sassy-color-white;
            padding: 0 $sassy-spacing-x-small;
        }
    }

    &.small {
        width: 100%;
    }
}

.itemInner {
    position: relative;
    width: 100%;

    &.xSmall {
        margin: $image-height-spacing-carousel $sassy-spacing-medium 0 0;
        height: $image-size-x-small-carousel;
        max-width: $image-size-x-small-carousel;

        @include sassy-breakpoint-below(tablet-portrait) {
            margin: $image-height-spacing-swiper $sassy-spacing-larger 0 0;
            height: $image-size-x-small-mobile-carousel;
            max-width: $image-size-x-small-mobile-carousel;
        }

        .alignTitleLeft & {
            margin: 0 $sassy-spacing-x-small;
            height: $image-size-left-aligned-title;
            max-width: 100%;
            padding: $sassy-spacing-x-small 0;
            background-color: $sassy-color-white;
        }

        .swiperItem & {
            margin: 0;
            height: $image-size-x-small-mobile-carousel;
            min-width: 90px;
            max-width: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.small {
        margin: $image-height-spacing-carousel $sassy-spacing-medium 0 0;
        height: $image-size-small-carousel;

        @include sassy-breakpoint-below(tablet-portrait) {
            margin: $image-size-small-spacing $sassy-spacing-medium 0 0;
            height: $image-size-small-mobile-carousel;
        }
    }

    &.large {
        height: $image-size-large-carousel;
        max-width: 346px;
        margin: 0 $sassy-spacing-small;
        padding: $sassy-spacing-medium 0 $sassy-spacing-small 0;

        @include sassy-breakpoint-below(tablet-portrait) {
            margin: $image-height-spacing-swiper $sassy-spacing-larger 0 0;
            height: $image-size-large-mobile-carousel;
            max-width: $image-size-large-mobile-carousel;
        }
    }
}

.imageLink {
    display: block;
}

.lazy,
.imageLink {
    width: 100%;
    height: 100%;

    // Move image left to prevent last item on page to be visible when 100% cannot be divided without leftover by itemsPerPage
    margin-left: 1px;

    &.xSmall {
        .swiperItem & {
            height: $image-size-swiper;
            max-width: $image-size-swiper;
        }
    }
}

.lazy {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-height: 100%;
    max-width: 100%;
}

.heartButton {
    position: absolute;

    &.xSmall {
        top: -$sassy-spacing-xx-small;
        right: -$sassy-spacing-medium;

        .alignTitleLeft &,
        .swiperItem & {
            top: $sassy-spacing-xx-small;
            right: -$sassy-spacing-xx-small;
        }
    }

    &.small {
        top: -$sassy-spacing-xx-small;
        right: -$sassy-spacing-medium;
    }

    &.large {
        top: $sassy-spacing-x-small;
        right: -$sassy-spacing-x-small;
    }
}

.heart {
    display: flex;
    align-items: center;
    justify-content: center;

    width: $heart-size-carousel;
    height: $heart-size-carousel;

    &.xSmall {
        .swiperItem & {
            width: $heart-size-swiper;
            height: $heart-size-swiper;
        }
    }
}
