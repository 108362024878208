$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-scroll-carousel/exports/_gapStyles';

.wrapper {
    @include wrapper-gap;
    @include scroll-padding;
}

.arrowSpacing {
    padding-left: calc(44px + #{$sassy-spacing-medium});
    padding-right: calc(44px + #{$sassy-spacing-medium});
}

.item {
    @include item-gap;
}

.shimmer {
    @include sassy-animate-loading-shimmer;
}
