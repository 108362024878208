$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_colors';

$image-height-resp: 250px;
$image-height-mobile: 200px;
$image-width-resp: 200px;
$image-size-mobile: 160px;

$heart-size-carousel: 35px;
$heart-size-swiper: 20px;

.itemWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .swiperItem & {
        width: 100%;
        background-color: $sassy-color-white;
    }
}

.itemInner {
    position: relative;
    width: 100%;
    margin: $sassy-spacing-x-small $sassy-spacing-medium 0 0;
    height: $image-height-resp;
    max-width: $image-width-resp;

    @include sassy-breakpoint-below(mobile) {
        margin: 0;
        max-width: $image-size-mobile;
        height: $image-height-mobile;
    }
}

.heartButton {
    position: absolute;
    top: -5px;
    right: -30px;
    .swiperItem & {
        top: 5px;
        right: 5px;
    }
}

.heart {
    display: flex;
    align-items: center;
    justify-content: center;

    width: $heart-size-carousel;
    height: $heart-size-carousel;

    .swiperItem & {
        width: $heart-size-swiper;
        height: $heart-size-swiper;
    }
}
