$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

:local {
    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .listWrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        padding: 0;
        overflow: hidden;
    }

    .list {
        position: relative;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transform: translate3d(0, 0, 0);
        list-style: none;

        .slide & {
            transition: transform 300ms ease-out 100ms;
        }
        &.isVerticallyAligned {
            flex-direction: column;
        }
    }

    .item {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .button {
        width: 100%;
        background: transparent;
        border: none;
        cursor: pointer;
        transition: color 150ms linear;
        color: $sassy-color-iron;
        fill: $sassy-color-iron;

        &:hover {
            color: $sassy-color-satan;
        }
    }

    .arrow {
        width: 40px;
    }

    .arrowSVG {
        width: 28px;
        height: 48px;
    }

    .arrowLeft {
        margin-right: 4%;
    }

    .arrowRight {
        margin-left: 4%;
    }

    .dotsWrapper {
        margin-top: $sassy-spacing-x-small;
        text-align: center;
    }
}
