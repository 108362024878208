$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

.primaryItem {
    text-align: center;
}

.primaryLink {
    height: 100%;
    display: block;
    background: $sassy-color-moonstone;
}

.primaryImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.productItems {
    @include sassy-breakpoint-above(tablet-landscape) {
        margin: -$sassy-spacing-x-small 0;
    }
}

.fullHeight {
    height: 100%;
}
