$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_hidden';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '../HpSharedModuleItemTile/mixins.scss';

.link {
    // required to render focus outline on focusable element containing block-like
    // elements
    display: block;
}

.item {
    margin-bottom: $sassy-spacing-large;
}

.fullWidth {
    display: flex;
    justify-content: center;
    @include primary-viewport-width-tablet-fix;
}

.image {
    display: block;
    max-height: 100%;
    width: 100%;

    @include sassy-breakpoint-above(tablet-landscape) {
        &.isFullBleed {
            @include primary-viewport-width;
            max-height: 400px;
            object-fit: cover;
        }
    }
}

.imageCopy {
    @include sassy-visually-hidden;
}

.tileImageOverlay {
    @include tile-image-overlay();
}

.tileImage {
    width: 100%;
}
