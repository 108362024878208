$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_colors';

.container {
    width: 100%;
    height: 100%;
    position: relative;

    .lazyPlaceholder,
    /**
     * Styling img tag instead of a more specific class selector because LazyLoad does not support passing className as a prop
     * Usually img is a grandchild of .container
     */
    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

.lazyPlaceholder {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
        to right,
        $sassy-color-moonstone 0%,
        $sassy-color-dolphin 50%,
        $sassy-color-dolphin 51%,
        $sassy-color-moonstone 100%
    );
    background-size: 400% 400%;

    &.shimmer {
        @include sassy-animate-loading-shimmer;
    }
}
