$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_spacing';

.container {
    min-height: 300px;
}

.gridImage {
    grid-area: image;
}

.playIcon {
    width: $sassy-spacing-larger;
}
